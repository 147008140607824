#loading-bar {
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;

  &[data-loading="true"] {
    &::after {
      display: block;
      content: "";
      height: 3px;
      background-color: $primary;
      position: absolute;
      bottom: 0;
      top: 0;
      width: 50%;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: progress-bar;
    }
  }

  @keyframes progress-bar {
    from {
      left: -50%;
    }
    to {
      left: 100%;
    }
  }
}

body {
  background-color: #000d18;
  color: white;
  padding: 0;
  margin: 0;
}

#wrapper {
  text-align: center;
}

#brawlhalla-logo {
  width: 400px;
  display: block;
  margin: 20px auto;
}

.legends-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  max-width: 1000px;
  margin: 0 auto;

  .legend {
    cursor: pointer;
  }
}

.filters-container {
  margin: 15px auto;
  max-width: 1000px;
  text-align: left;
}

.stat {
  display: flex;
  margin-bottom: 5px;

  img {
    margin-right: 10px;
    display: block;
    cursor: pointer;

    &.upgrade {
      outline: 4px solid #03a9f424;
      filter: drop-shadow(0px 0px 10px rgb(6, 186, 210));
    }
  }

  .stat-value {
    flex: 1;
    margin-bottom: 5px;
    border-style: solid;
    border-width: 2px 2px 2px 0;

    &:nth-child(2) {
      border-width: 2px 2px 2px 2px !important;
    }

    &.active {
      background: rgb(6, 186, 210);
      background: linear-gradient(0deg, rgba(6, 186, 210, 1) 0%, rgba(6, 186, 210, 1) 50%, rgba(81, 228, 247, 1) 50%, rgba(81, 228, 247, 1) 100%);
      border-color: #179CB0;
    }

    &.inactive {
      background: rgb(42, 57, 68);
      background: linear-gradient(0deg, rgba(42, 57, 68, 1) 0%, rgba(49, 66, 78, 1) 50%, rgba(56, 74, 88, 1) 50%, rgba(59, 78, 92, 1) 100%);
      border-color: #112241;
      border-left-width: 0;
    }

    &.upgrade {
      background: #83F316;
      background: linear-gradient(0deg, #08B958 0%, #08B958 50%, #83F316 50%, #83F316 100%);
      border-color: #067D3B;
    }

    &.degrade {
      background: rgb(42, 57, 68);
      background: linear-gradient(0deg, rgba(42, 57, 68, 1) 0%, rgba(49, 66, 78, 1) 50%, rgba(56, 74, 88, 1) 50%, rgba(59, 78, 92, 1) 100%);
      border-color: #179CB0;
    }
  }
}

#layout {
  display: flex;

  header {
    flex: 1;
    background-color: #001529;
  }

  main {
    flex: 1;
    background: transparent !important;
  }
}

#loading {
  height: 100vh;
  width: 100vw;
  display: block;
  .ant-image {
    width: 400px;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}




$input-background-color: #002443;
$input-border-color: #ffffff1a;
$input-placeholder-color: rgba(255, 255, 255, 0.25);
$input-text-color: rgba(255, 255, 255, 0.75);

.ant-select {
  .ant-select-selector {
    background-color: $input-background-color !important;
    border-color: $input-border-color !important;

    .ant-select-selection-placeholder {
      color: $input-placeholder-color !important;
      text-align: left !important;
    }

    input {
      color: $input-text-color !important;
    }

    .ant-select-selection-overflow-item {
      color: $input-text-color !important;
      .ant-select-selection-item {
        background-color: $input-placeholder-color !important;
        .ant-select-selection-item-remove {
          color: $input-text-color !important;
        }
      }
    }
  }
  .ant-select-arrow {
    color: $input-placeholder-color !important;
  }
}

.ant-select-dropdown {
  background-color: $input-background-color !important;
  .ant-select-item {
    color: $input-text-color !important;
    &:hover {
      background-color: #00335b !important;
    }
    &.ant-select-item-option-selected {
      background-color: #00335b !important;
    }
  }
}

.ant-typography {
  color: $input-text-color !important
}

.ant-card {
  background-color: #001425 !important;
  border-color: #ffffff1a !important;
  .ant-card-head {
    border-color: #ffffff1a !important;
    color: $input-text-color !important;
    text-align: left !important;
  }
}

.ant-btn {
  &.ant-btn-default {
    background-color: $input-background-color;
    border-color: $input-border-color;
    color: $input-text-color;
    &:hover,&:active {
      background-color: lighten($input-background-color, 10) !important;
    }
  }
}

.ant-drawer {
  .ant-drawer-content {
    background-color: #000d18;
  }
}

.ant-layout {
  background: unset;
}

.ant-menu {
  background-color: transparent !important;
  .ant-menu-item {
    color: white;
  }
  .ant-menu-item-active {
    color: white !important;
  }
}

.ant-input-group-wrapper {
  .ant-input-group-addon {
    user-select: none;
    background: lighten(#000d18, 3);
    border-color: $input-border-color;
    color: $input-text-color
  }
  .ant-input-affix-wrapper {
    background: $input-background-color;
    border-color: $input-border-color;
    color: $input-text-color;
    height: 32px;
  }
  .ant-input-clear-icon {
    color: rgb(255 255 255 / 25%);
  }
  input::placeholder {
    color: $input-placeholder-color
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  button {
    border-color: #F43C3B !important;
    background: #FFF68A !important;
    background: linear-gradient(0deg, #F43C3B 0%, #FFF68A 100%) !important;
    &:hover {
      color: rgb(255 255 255) !important;
    }
  }
}

.ant-breadcrumb {
  li {
    user-select: none;
    &:not(:last-child) {
      .ant-breadcrumb-link {
        color: rgba(255, 255, 255, 0.45);
        &:hover {
          color: rgba(255, 255, 255, 0.70);
        }
      }
    }
    &.ant-breadcrumb-separator {
      color: rgba(255, 255, 255, 0.20);
    }
    &:last-child {
      .ant-breadcrumb-link {
        color: rgba(255, 255, 255, 0.88);
      }
    }
  }
}
